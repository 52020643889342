<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Warehouse</label>
                    <Dropdown dataKey="wrh_id" v-model="filters.wrh_id" :loading="loadingDropdownWarehouse"
                        :options="dataDropdownWarehouse"
                        optionLabel="label" optionValue="wrh_id" placeholder="Pilih Warehouse"
                        :filter="true" :showClear="true" @filter="searchDropdownWarehouse($event, 'filter')"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>MINB No</label>
                    <Dropdown dataKey="minb_no" v-model="filters.minb_no" :loading="loadingDropdownMinbNo"
                        :options="dataDropdownMinbNo"
                        optionLabel="minb_no" optionValue="minb_no" placeholder="Pilih MINB No"
                        :filter="true" :showClear="true" @filter="searchDropdownMinbNo($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownWarehouse: false,
            loadingDropdownMinbNo: false,

            // dataDropdown
            dataDropdownWarehouse: null,
            dataDropdownMinbNo: null,

            // filter
            filters: {
                wrh_id: null,
                minb_no: null,
            },
        }
    },
    mounted() {
        this.searchDropdownWarehouse('');
        this.searchDropdownMinbNo('');
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownWarehouse(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dwrh.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownWarehouse = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/inv-wrh',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownWarehouse = res.data.data;
                            this.loadingDropdownWarehouse = false;
                        } else if (purpose == null) {
                            this.dataDropdownWarehouse = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownMinbNo(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dminbno.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownMinbNo = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-minb',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownMinbNo = res.data.data;
                            this.loadingDropdownMinbNo = false;
                        } else if (purpose == null) {
                            this.dataDropdownMinbNo = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

